<template>
  <div class="list-page" v-loading="loading">
    <el-button class="top-btn" size="small" type="primary" @click="handleShowAdd()">添加分类</el-button>
    <el-table type="expand" style="width: 100%;" class="thead-light" :data="classifyList" stripe row-key="id"
      :tree-props="{children: '_children',hasChildren: 'hasChildren'}">
      <el-table-column prop="id" label="ID" min-width="100"></el-table-column>
      <!--            <el-table-column prop="sort" label="排序" width="230"></el-table-column>-->
      <el-table-column label="排序" min-width="100">
        <template slot-scope="scope">
          <popover-input-edit v-model="scope.row.sort" autofocus
                              @confirm="updateIsShow(scope.row.sort, scope.row.id, scope.$index)"
          ></popover-input-edit>
        </template>
      </el-table-column>
      <el-table-column prop="name" label="名称" min-width="100"></el-table-column>
      <el-table-column prop="articles_count" label="文章数量" min-width="100"></el-table-column>
      <el-table-column label="显示" min-width="100">
        <template slot-scope="scope">
          <el-checkbox v-model="scope.row.status" :true-label="1" :false-label="0"
            @change="handleChangeStatus(scope.row)"></el-checkbox>
        </template>
      </el-table-column>
      <el-table-column label="操作" width="150" fixed="right">
        <template slot-scope="scope">
          <el-button style="color: #3576FF" size="small" type="text" @click="handleShowAdd(scope.row)">编辑
          </el-button>
          <el-button style="color: #3576FF" size="small" type="text" @click="handleShowTransfer(scope.row)">转移
          </el-button>
          <el-button style="color: #3576FF" size="small" type="text" @click="handleDeleteClassify(scope.row)">
            删除</el-button>
        </template>
      </el-table-column>
    </el-table>
    <Pagination :total="total" :page="page" :change="changPage"></Pagination>
    <el-dialog title="分类" width="500px" class="dialog-vertical" :visible.sync="showTransfer">
      <el-form ref="classifyForm" :model="classifyForm" label-width="100px" :rules="rules">
        <el-form-item label="目标分类" prop="name">
          <el-select style="width: 100%" placeholder="请选择分类" v-model="transferTargetId">
            <el-option v-for="(classify,index) in classifys" :key="classify.id" :label="classify.name"
              :value="classify.id"></el-option>
          </el-select>
          <div style="color: #c0c4cc;font-size: 12px;margin-top: 5px;">将内容移至选择的分类</div>
        </el-form-item>
      </el-form>
      <template #footer>
        <el-button size="small" @click="showTransfer = false">取消</el-button>
        <el-button size="small" type="primary" @click="handleTransfer">保存</el-button>
      </template>
    </el-dialog>
    <AddClassify :showAddClassify="showAddClassify" :editObj="editObj"
      @closeAddClassify="showAddClassify = false" @updateClassifyList="updateClassifyList"></AddClassify>
  </div>
</template>

<script>
import {
  classifyList,
  updateStatus,
  deleteClassify,
  allClassifyData,
  moveClassify,
  updateSort,
} from "../api/classify-list";
import AddClassify from "../components/ClassfiyArticle/AddClassify";
import Pagination from "@/base/components/Default/Pagination";
import PopoverInputEdit from "../../../base/components/Popover/PopoverInputEdit";

export default {
  name: "classify-article",
  data() {
    return {
      classifyList: [],
      showAddClassify: false,
      showTransfer: false,
      //当前选中id
      transferId: 0,
      //要转移的目标id
      transferTargetId: "",
      classifys: [],
      //编辑对象
      editObj: "",
      //loading动画
      loading: false,
      total: 0,
      page: 1,
      page_size: 15,
      classifyForm: {
        name: "",
      },
      rules: {
        name: [{ required: true, message: "请选择分类", trigger: "blur" }],
      },
    };
  },
  components: {
    PopoverInputEdit,
    AddClassify,
    Pagination,
  },
  created() {
    this.getTables();
  },
  methods: {
    changPage(data) {
      this.page = data.page;
      this.page_size = data.page_size;
      this.getTables();
    },
    updateClassifyList() {
      this.loading = true;
      this.getTables();
      this.editObj = "";
    },
    getTables() {
      this.loading = true;
      let data = {
        page: this.page,
        page_size: this.page_size,
      };
      classifyList(data)
        .then((res) => {
          this.classifyList = res.data.data;
          // this.classifyList.forEach((e,i)=>{
          //     if(e._children){
          //         this.classifyList[i].hasChildren = true
          //     }
          // })
          this.total = res.data.total;
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
    handleShowAdd(data) {
      if (data && data.id && data.id != "0") {
        this.editObj = data;
      } else {
        this.editObj = {
          id: 0,
        };
      }
      this.showAddClassify = true;
    },
    handleChangeStatus(item) {
      let data = {
        id: item.id,
      };
      if (item.status == "0") {
        data.status = 0;
      } else {
        data.status = 1;
      }
      updateStatus(data)
        .then((res) => {
          this.$message({
            message: "状态修改成功",
            type: "success",
          });
        })
        .catch(() => {});
    },
    updateIsShow(e, id, i) {
      updateSort({ id: id, sort: Number(e) })
        .then((res) => {
          this.$message({
            type: "success",
            message: "更新成功",
          });
        })
        .catch(() => {});
    },
    handleDeleteClassify(e) {
      this.$confirm("此操作将删除该分类, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          deleteClassify({ id: e.id })
            .then((res) => {
              this.getTables();
              this.$message({
                type: "success",
                message: "删除成功!",
              });
            })
            .catch(() => {});
        })
        .catch(() => {});
    },
    handleShowTransfer(item) {
      this.transferId = item.id;
      this.showTransfer = true;
      allClassifyData()
        .then((res) => {
          this.classifys = res.data;
        })
        .catch();
    },
    handleTransfer() {
      let data = {
        id: this.transferId,
        target_id: this.transferTargetId,
      };
      moveClassify(data)
        .then((res) => {
          this.$message({
            message: "转移成功",
            type: "success",
          });
          this.showTransfer = false;
          this.getTables();
        })
        .catch(() => {});
    },
  },
};
</script>

<style scoped lang="scss">
.classify-box {
  width: 100%;
  box-sizing: border-box;
}
.dialog-bottom {
  width: 100%;
  display: flex;
  align-items: center;
  margin-top: 20px;
  justify-content: center;
}
</style>
