<template>
  <el-dialog v-if="showAddClassify" :title="(editObj && editObj.id ? '编辑':'添加') + '分类'" :visible="showAddClassify" width="504px"
    class="dialog-vertical" @close="closeAdd">
    <el-form ref="classifyForm" size="medium" :model="classifyForm" label-width="80px" :rules="rules" @submit.native.prevent>
      <el-form-item label="名称" prop="name">
        <el-input v-model="classifyForm.name" placeholder="输入名称" v-focus></el-input>
      </el-form-item>
      <el-form-item label="上级分类">
        <el-select v-model="classifyForm.region" placeholder="请选择上级分类">
          <el-option label="无" :value="0" :key="0"></el-option>
          <el-option v-for="(item,index) in regionList" :label="item.name" :value="item.id" :key="item.id">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="排序">
        <el-input v-model="classifyForm.sort" placeholder="请填写排序"></el-input>
      </el-form-item>
    </el-form>
    <template #footer>
      <el-button size="small" @click="closeAdd">取消</el-button>
      <el-button size="small" type="primary" @click="handleAddClassify" :loading="saveLoading">确定</el-button>
    </template>
  </el-dialog>
</template>

<script>
import { classifyData, addClassify } from "../../api/classify-list";

export default {
  name: "AddClassify",
  props: ["showAddClassify", "editObj"],
  data() {
    return {
      classifyForm: {
        name: "",
        region: "",
        sort: "",
      },
      regionList: [],
      rules: {
        name: [{ required: true, message: "请输入分类名称", trigger: "blur" }],
        region: [
          { required: true, message: "请选择上级分类", trigger: "blur" },
        ],
        sort: [{ required: true, message: "请输入分类排序", trigger: "blur" }],
      },
      saveLoading: false,
    };
  },
  watch: {
    showAddClassify(val) {
      if (val && !this.regionList.length) {
        classifyData()
          .then((res) => {
            this.regionList = res.data;
          })
          .catch(() => {});
      }
      if (val) {
        if (this.editObj && this.editObj.id != 0) {
          this.classifyForm.name = this.editObj.name;
          this.classifyForm.region = this.editObj.pid;
          this.classifyForm.sort = this.editObj.sort;
        }
      }
    },
  },
  methods: {
    closeAdd() {
      this.classifyForm = {
        name: "",
        region: "",
        sort: "",
      };
      this.$emit("closeAddClassify");
    },
    handleAddClassify() {
      let data = {
        name: this.classifyForm.name,
        pid: this.classifyForm.region,
        sort: this.classifyForm.sort,
      };
      if (this.editObj && this.editObj.id != 0) {
        data.id = this.editObj.id;
      } else {
        data.id = 0;
      }
      this.saveLoading = true;
      addClassify(data)
        .then((res) => {
          if (this.editObj.id) {
            this.$message({
              message: "修改成功",
              type: "success",
            });
          } else {
            this.$message({
              message: "新增成功",
              type: "success",
            });
          }
          this.closeAdd();
          this.saveLoading = false;
          this.$emit("updateClassifyList");
        })
        .catch(() => {
          this.saveLoading = false;
        });
    },
  },
};
</script>

<style scoped lang="scss">
.el-select {
  width: 100%;
}

.dialog-bottom {
  width: 100%;
  display: flex;
  align-items: center;
  margin-top: 20px;
  justify-content: center;
}
</style>
